#login-page{
    /*background-color: #2a5a76;*/
    width: 100%;
    height: 100%;
    z-index: 1;
    /*position: absolute;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-logo{
    width: 20rem;
    margin-top: 2rem;
}

#login-title{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

label{
    color:white;
    margin-bottom: 0.5rem;

}

input{
    background-color: #6b8ea2;
    border-radius: 0.5rem;
    border: 0 transparent;
    height: 2rem;
    width: 15rem;
    color: white;
    font-size: 1rem;
    text-align: center;
}

input:active{
    border: 0 transparent;
}

button{
    color: #2a5a76;
    border: 0.2rem solid #2a5a76;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #bdcbdd;
    cursor: pointer;
}

button:active{
    border-color: #bdcbdd;
    color: #bdcbdd;
    background-color: #2a5a76;
}