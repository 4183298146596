body::-webkit-scrollbar {
    display: none; /* Chrome */
}

body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media (min-width: 601px){

    input[type='text']{
        text-align: center;
    }

    .cards-container{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 2rem;
        margin-bottom: 2rem;
    }

    #rand-item{
        position: fixed;
        right: 1rem;
        width: 4rem;
        top: 10rem;
        cursor: pointer;
        z-index: 2;
    }

    #add-item{
        position: fixed;
        right: 1rem;
        width: 4rem;
        top: 15rem;
        cursor: pointer;
        z-index: 2;
    }

    #refresh{
        position: fixed;
        right: 1rem;
        width: 4rem;
        top: 20rem;
        cursor: pointer;
        z-index: 2;
    }
}

/***************** for mobile device *****************/

@media (max-width: 600px){

    input[type='text']{
        text-align: center;
    }

    .cards-container{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 0.5rem;
        margin-bottom: 2rem;
    }

    #rand-item{
        position: fixed;
        right: 0.5rem;
        width: 3rem;
        top: 15rem;
        cursor: pointer;
        z-index: 2;
    }

    #add-item{
        position: fixed;
        right: 0.5rem;
        width: 3rem;
        top: 18.5rem;
        cursor: pointer;
        z-index: 2;
    }

    #refresh{
        position: fixed;
        right: 0.5rem;
        width: 3rem;
        top: 22rem;
        cursor: pointer;
        z-index: 2;
    }

}