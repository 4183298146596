@media (min-width: 601px){

    #alert-container{
        position: fixed;
        background-color: #6b8ea2;
        padding: 1rem 2rem 2rem 2rem;
        border-radius: 1rem;
        border: 0.2rem solid #2a5a76;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #alert-container1{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #alert-container2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
    }

    #alert-title{
        font-size: 1.2rem;
        font-weight: bold;
        color: #2a5a76;
        margin: 0;
    }

    #alert-cross{
        width: 2rem;
        cursor: pointer;
    }

    #alert-image{
        height: 7rem;
        margin-right: 2rem;
    }

    #alert-text{
        color: white;
        font-size: 1rem;
        text-align: center;
    }
}

/***************** for mobile device *****************/

@media (max-width: 600px){

    #alert-container{
        position: fixed;
        background-color: #6b8ea2;
        padding: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        border: 0.2rem solid #2a5a76;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #alert-container1{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #alert-container2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
    }

    #alert-title{
        font-size: 1.2rem;
        font-weight: bold;
        color: #2a5a76;
        margin: 0;
    }

    #alert-cross{
        width: 1.5rem;
        cursor: pointer;
    }

    #alert-image{
        height: 4rem;
        margin-bottom: 1rem;
    }

    #alert-text{
        color: white;
        font-size: 1rem;
        text-align: center;
    }
}